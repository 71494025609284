import axios, {  AxiosInstance } from "axios";
import throwError from "../service/throwError";
import {
  OpeningRes,
  getOpeningRes,
  createOpeningMembershipRes,
  ApplicantRes,
  getListByListIDsRes,
  getOpeningMembersRes,
  getBoardLabelRes,
  getApplicantCommentsRes,
  CommentRes,
  getApplicantAttachmentsRes,
  AddAttachmentRes,
  AddAttachment,
  updateApplicantAttachmentRes,
  registrationRes,
  Register,
  JWTtokenExchangeRes,
  getOrgMemberRes,
  ActivityRes,
  copyAttachmentsRes,
  getFreeTierQuotaRes,
  getEventAttachmentRes,
  uploadAttachmentOfCandidateRes,
  addWatcherRes,
  getWatcherRes,
  PaginationActivity,
  ActivityPaginationRes,
  SourcesResponse,
  Source,
  guestLoginRes,
  Login,
  generateSecureLinkRes,
  Secret,
} from "./apiRes";
import {
  atsUploadHeader,
  authHeader1,
  authHeader,
} from "../service/authHeaders";
import {
  Applicant,
  comment,
  Label,
  List,
  Member,
  Opening,
  Candidate,
  Attachment,
  CandidateShortReview,
  ReviewComment,
  paginationDetails,
  SendMail,
  RecordThreads,
  Mail,
  Domain,
  activity,
  FormBuilder,
  Jd,
} from "../type/type";
import {
  updatedOpeningReq,
  AddMemberReq,
  addWatcherReq,
} from "./apiReq";
const epBoard = "/v1/ats/secure-share/openings/";
const epList = "/v1/ats/secure-share/lists/";
const epLabels = "/v1/ats/secure-share/labels/";
const epMembers = "/v1/ats/secure-share/members/";
const epApplicant = "/v1/ats/secure-share/applicants";
const epComments = "/v1/ats/secure-share/comments";
const epAttachment = "/v1/ats/secure-share/attachments/";
const epFusion = "/v1/orgs/auth/user/";
const epNotification = "/v1/notifications/";
const epActivity = "/v1/activity/secure-share/";
const epBilling = "/v1/billing/"
const epCDB = "/v1/db/";
const epCalender = "/v1/calendar/";
const epJD = "/v1/jd/secure-share/";
let api: AxiosInstance;

export const configureAPI = (baseURL: string | undefined) => {
  api = axios.create({ baseURL: baseURL });
};
/**************************************** Ats api *********************************************************/
//openings
export function getOpenings() {
  const ep = epBoard;
  
  return new Promise<Opening[]>((resolve, reject) => {
    api
      .get<getOpeningRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.openings);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
}


export const getBoardByid = (id: string) => {
  const ep = epBoard + id;
  return new Promise<Opening>((resolve, reject) => {
    api
      .get<OpeningRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const updateOpening = (updatedOpening: updatedOpeningReq) => {
  const ep = epBoard + "update/" + updatedOpening.id;
  return new Promise<Opening>((resolve, reject) => {
    api
      .put<OpeningRes>(ep, updatedOpening, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};



export const createOpeningMembership = (req: AddMemberReq) => {
  const ep = epMembers + "openingMembership/" + req.id;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .post<createOpeningMembershipRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.members) {
          resolve(res.data.members);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};



export const getListByListIDs = (openingID: string) => {
  const ep = epList + openingID;
  return new Promise<List[]>((resolve, reject) => {
    api
      .get<getListByListIDsRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.lists_with_applicants);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};


export const getApplicant = (applicantID: string) => {
  const ep = epApplicant + `/${applicantID}`;
  return new Promise<Applicant>((resolve, reject) => {
    api
      .get<ApplicantRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          const applicant: Applicant = res.data.applicant;
          resolve(applicant);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editApplicantDescription = (desc: any) => {
  const ep = epApplicant + "/description/" + desc.id;
  return new Promise<Applicant>((resolve, reject) => {
    api
      .put<ApplicantRes>(ep, desc, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};


export const getOpeningMembers = (openingID: string, applicationId: string) => {
  const ep = `${epMembers}${openingID}/${applicationId}/openingMembers`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOpeningMembersRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          res.data.members = res.data.members ? res.data.members : [];
          resolve(res.data.members);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getBoardLabel = (openingID: any) => {
  const ep = epLabels + "opening" + "/" + openingID + "/";
  return new Promise<Label[]>((resolve, reject) => {
    api
      .get<getBoardLabelRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.labels);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getApplicantComments = (applicantID: string) => {
  const ep = epComments + `/${applicantID}/applicant`;
  return new Promise<comment[]>((resolve, reject) => {
    api
      .get<getApplicantCommentsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comments);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createComment = (newComment: any) => {
  const ep = epComments + "/" + newComment.applicant_id;
  return new Promise<comment>((resolve, reject) => {
    api
      .post<CommentRes>(ep, newComment, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comment);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editComment = (req: any, applicantId: string) => {
  const ep = epComments + "/" + applicantId;
  return new Promise<comment>((resolve, reject) => {
    api
      .put<CommentRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comment);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const moveApplicant = (req: any) => {
  const ep = epApplicant + "/move/" + req.src_applicant.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//get attachment
export const getApplicantAttachments = (applicantID: string) => {
  const ep = epAttachment + `applicants/${applicantID}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .get<getApplicantAttachmentsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const moveList = (req: any) => {
  const ep = epList + "move/" + req.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.move_list);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const moveListAplicants = (req: any) => {
  const ep = epList + "moveListApplicants/" + req.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.move_list_applicants);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const dragApplicant = (dragedApplicant: any) => {
  const ep = epApplicant + "/move/" + dragedApplicant.src_applicant.id;
  return api.put(ep, dragedApplicant, { headers: authHeader() });
};



export const copyApplicant = (req: any) => {
  const ep = epApplicant + "/copy/" + req.src_applicant.id;
  return new Promise((resolve, reject) => {
    api
      .post(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.copy_applicant);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//upload attachment
export const addAttachment = (
  formData: any,
  id: string,
  onUploadProgress?: any,
  onDownloadProgress?: any
) => {
  const ep = epAttachment + id;
  return new Promise<AddAttachment>((resolve, reject) => {
    api
      .post<AddAttachmentRes>(ep, formData, {
        headers: atsUploadHeader(),
        onUploadProgress,
        onDownloadProgress,
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachment);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const copyAttachments = (ats: { [key: string]: Attachment[] }) => {
  const ep = epAttachment + "copy";
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .post<copyAttachmentsRes>(ep, ats, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachment);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const deleteApplicantAttachment = (req: any) => {
  const ep = epAttachment;
  return new Promise((resolve, reject) => {
    api
      .delete(ep, { headers: authHeader(), data: req })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.deleted_attachment);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const updateApplicantAttachment = (attachment: any) => {
  const ep = epAttachment + attachment.id;
  return new Promise<Attachment>((resolve, reject) => {
    api
      .put<updateApplicantAttachmentRes>(ep, attachment, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachment);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const setTokenInLocalStorage = (
  tokenIdentifire: string,
  token: string
) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      localStorage.setItem(tokenIdentifire, token);
      resolve(true);     
    } catch {
      reject(false);
    }
  });
};

export const setTokenInSessionStorage = (token: string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      sessionStorage.setItem("current_tab_application_token", token);
      resolve(true);
    } catch {
      reject(false);
    }
  });
};
export const setAnyTokenInSessionStorage = (key: string,value:string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      sessionStorage.setItem(key, value);
      resolve(true);
    } catch {
      reject(false);
    }
  });
};


/********************************* fusion auth  ******************************/





//login
export const login = (loginObject: any) => {
  const ep = epFusion + "login";
  return new Promise<Register>((resolve, reject) => {
    api
      .post<registrationRes>(ep, loginObject)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.user);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

//token exchange
export const JWTtokenExchange = (orgId: string, loginUserId: string) => {
  const ep = epFusion + `jwt/exchange/${orgId}/${loginUserId}`;
  return new Promise<string>((resolve, reject) => {
    api
      .get<JWTtokenExchangeRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.application_based_token.token);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getOrg member
export const getOrgMember = (orgId: string) => {
  const ep = epFusion + `secure-share/${orgId}`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOrgMemberRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};





/*********************************************** Acitity **********************************************************/
export const getActivityByPagination = (modelName: string, modelId: any, page?: string, per_page?: string) => {
  let ep: string;

    ep =
    epActivity + `${modelName}/${modelId}/?page=${page}&per_page=${per_page}`;
    return new Promise<PaginationActivity & paginationDetails>(
      (resolve, reject) => {
        api
          .get<ActivityPaginationRes>(ep, { headers: authHeader() })
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data.activities);
            }
          })
          .catch((reason: any) => {
            throwError(reason);
            reject(reason);
          });
      }
    );
};
export const getActivity = (modelName: string, modelId: any) => {
  const ep = epActivity + `${modelName}/${modelId}`;
  return new Promise<activity[]>((resolve, reject) => {
    api
      .get<ActivityRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          res.data.activities = res.data.activities ? res.data.activities : [];
          resolve(res.data.activities);
        }
      })
      .catch((reason: any) => {
        throwError(reason)
        reject(reason);
      });
  });
};
/*************************************** notification **************************************************************/

// addWatcher
export const addWatcher = (Req: addWatcherReq) => {
  const ep = epNotification + `subscriptions`;
  return new Promise<addWatcherRes>((resolve, reject) => {
    api
      .post<addWatcherRes>(ep, Req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// addWatcher
export const removeWatcherOfEntity = (entity_id: string,entity_name:string,user_id:string) => {
  const ep = epNotification + `subscriptions/${entity_name}/${entity_id}/${user_id}`;
  return new Promise<addWatcherRes>((resolve, reject) => {
    api
      .delete(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getWatcher
export const getWatcherOfEntity = (entity_id: string) => {
  const ep = epNotification + `subscriptions/${entity_id}`;
  return new Promise<getWatcherRes>((resolve, reject) => {
    api
      .get<getWatcherRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};



// ***************************************************** calender *************************************************************//
// incomplete
// getEvent
export const getEvent = (event_id: string) => {
  const ep = epBilling + `customers/${event_id}/free_tier_limit`;

  return new Promise<getFreeTierQuotaRes>((resolve, reject) => {
    api
      .get<getFreeTierQuotaRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: any) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};




















//addAttachmentInCalEvent
export const getAttachmentByEventID = (event_id: string) => {
  const ep = epCalender + `event/attachments/events/${event_id}`;

  return new Promise<getEventAttachmentRes>((resolve, reject) => {
    api
      .get<getEventAttachmentRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: any) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};









/*************************** candidate DB  *********************************/


export const getAttachments = (model: string, recordId: string) => {
  const ep = epCDB + `attachments?model_name=${model}&record_id=${recordId}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .get<getApplicantAttachmentsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.attachments);
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const deleteAttachmentOfCandidate = (recordId: string) => {
  const ep = epCDB + `attachments?id=${recordId}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .delete<getApplicantAttachmentsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.attachments);
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};



export const uploadAttachmentOfCandidate = (
  formData: any,
  modelName: string,
  recordId: string,
  org_id:string,
  user_id:string,
) => {
  const ep =
    epCDB + `attachments/pub?model_name=${modelName}&record_id=${recordId}&org_id=${org_id}&user_id=${user_id}&type=form`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .post<uploadAttachmentOfCandidateRes>(ep, formData, {
        headers: atsUploadHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};














/********************************************** form builder **********************************************************/






/************************************************ JD  *****************************/





export const getJDbyBoardId = (boardId: string) => {
  const ep = epJD + `board/${boardId}`; 

  return new Promise<Jd>((resolve, reject) => {
    api.get(ep,{ headers: authHeader() }).then((res) => {
      if (res.status === 200) {
        resolve(res.data.jd);
      } else {
        reject(new Error('Failed to fetch job description'));
      }
    }).catch((reason: any) => {
      reject(reason);
    });
  });
};












/********************************************* GPT  ****************************************************/


//Function for fetching the all sources 
export const getAllSources = () => {
  const ep = epJD + "source";
  return new Promise<Source[]>((resolve, reject) => {
    api
      .get<SourcesResponse>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.source_tracking);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
}


//getApplicantStatus
export const getApplicantStatus = (ids: { ids: string[] }) => {
  const ep = epCDB + "candidates/bulk/activity"
  return new Promise((resolve, reject) => {
    api
      .post(ep, ids, {
        headers: authHeader(),
      },)
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.activities)
        }
      })
      .catch((reason: any) => {
        throwError(reason)
        reject(reason)
      })
  })
}









//  api's
// Login 


export const guestLogin = (base64:string) => {
  const ep = epFusion + `login/${base64}`;

  return new Promise<Login>((resolve, reject) => {
    api
      .post<guestLoginRes>(ep, null)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.login);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

export const generateSecureLink = (base64:string) => {
  const ep = epFusion + `login/secret/regenerate?secret=${base64}`;

  return new Promise<Secret>((resolve, reject) => {
    api
      .post<generateSecureLinkRes>(ep, null)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.secret);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};


