import { Image } from 'antd'
import React from 'react'
// import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import secureShareLogo from '../../images/secure-share_logo_white.png'

const TacitbaseLogoWithLabel = () => {
  return (
    <>
      <div className='flex items-center justify-center'>
        <Image width={150} src={secureShareLogo} preview={false} />
      </div>
    </>
  )
}
export default TacitbaseLogoWithLabel
