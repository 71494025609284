import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// import TextareaAutosize from "react-textarea-autosize";
import { Dispatch } from "redux";
import { updateListName } from "../../../redux/actions/opening/action";
import { EditListInputProps } from "../../../type/boardPanal";
import { Typography } from "antd";
const { Text } = Typography

const EditListInput = (props: EditListInputProps) => {
  const [updatedlistName, setNewListName] = useState<string>();


  useEffect(() => {
    setNewListName(props.listName);
    return () => {};
  }, [props.listName]);


  /* here check button is clicking or key pressed, if pressed then check predded key is enter or not.  
  if match then check latest list value with trim is equal to existing, if yes then dont call api otherwise call with new listname.  
  */
  // const updateListName = (e: any) => {
  //   if (
  //     (e.keyCode === 13 && !e.shiftKey) ||
  //     e.button == 0 ||
  //     e.type == "blur"
  //   ) {
  //     e.preventDefault();
  //     if (txtareaRef.current && txtareaRef.current.value) {
  //       let name = txtareaRef.current.value.trim();
  //       const req = {
  //         id: props.listID,
  //         name: name,
  //         socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
  //       };
  //       if (name !== "" && name !== props.listName) {
  //         editListName(req)
  //           .then((list) => {
  //             props.updateListName(list);
  //             isListEditable(false);
  //             txtareaRef.current?.blur();
  //           })
         
  //       } else {
  //         if (txtareaRef.current) {
  //           isListEditable(false);
  //           txtareaRef.current?.blur();
  //           setNewListName(props.listName);
  //         }
  //       }
  //     } else {
  //       if (txtareaRef.current) {
  //         txtareaRef.current?.blur();
  //         isListEditable(false);
  //         setNewListName(props.listName);
  //       }
  //     }
  //   }
  // };

  return (
    <div className=" w-full">
      <Text className={`  bg-black bg-opacity-0  flex items-center text-white focus:outline-none tracking-wider resize-none font-medium w-full px-1.5      text-sm"} `} style={{paddingTop:9.7,paddingBottom:9.7}}  
      >{updatedlistName}</Text>
   
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateListName: (list: any) => dispatch(updateListName(list)),
});
export default connect(mapStateToProps, mapPropsToState)(EditListInput);
