import React from "react";
import ListC from "./list/List";
import { DragDropContext, Droppable } from "react-beautiful-dnd/";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  draggableCard,
  draggableList,
  initCurrentBoard,
} from "../../redux/actions/opening/action";
import { dragApplicant, moveList } from "../../lib/api";
import { List, Opening } from "../../type/type";
import { SOCKET_CLIENT_ID } from "../../constant";
// import TabAction from "../jobPost/header/TabAction";
// import Filter from "../subNavbar/filter/Filter";
// import Read from "../../Read";

export type BoardPanalProps = {
  dragCard(result: any): void;
  dragList(result: any): void;
  lists: List[];
  currentOpening: Opening;
  clientId: string;
  saveCurrentBoard: (opening: Opening) => void;
};
const BoardPanel = (props: BoardPanalProps) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }
    if (result.type === "list") {
      const { source, destination, draggableId } = result;
      var value = Object.values(props.lists);
      let prevListID;
      if (destination.index === 0) {
        prevListID = "00000000-0000-0000-0000-000000000000";
      } else {
        if (destination.index < source.index) {
          prevListID = value[destination.index - 1].id;
        } else {
          prevListID = value[destination.index].id;
        }
      }
      const req = {
        src_list: {
          id: draggableId,
          opening_id: props.currentOpening.id,
          socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
          position: result.source.index,
        },
        dst_list: {
          id: prevListID,
          opening_id: props.currentOpening.id,
          position: result.destination.index,
        },
        type: "DRAG",
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),

      };
      moveList(req)
        .then((res: any) => {
          res;
        })
     

      props.dragList(result);
    } else {
      const { draggableId, destination, source } = result;

      let prevApplicantID;
      if (destination.index === 0) {
        prevApplicantID = "00000000-0000-0000-0000-000000000000";
      } else {
        if (source.droppableId != destination.droppableId) {
          prevApplicantID =
            props.lists[destination.droppableId].applicants[
              destination.index - 1
            ].id;
        } else {
          if (destination.index < source.index) {
            prevApplicantID =
              props.lists[destination.droppableId].applicants[
                destination.index - 1
              ].id;
          } else {
            prevApplicantID =
              props.lists[destination.droppableId].applicants[destination.index]
                .id;
          }
        }
      }
      const req = {
        src_applicant: {
          id: draggableId,
          list_id: source.droppableId,
          opening_id: props.currentOpening.id,
          position: source.index,
        },
        dst_applicant: {
          id: prevApplicantID,
          list_id: destination.droppableId,
          opening_id: props.currentOpening.id,
          position: destination.index,
        },
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        type: "DRAG",
      };
      dragApplicant(req)
        .then(() => {
        
        })
       .catch(() => {
          
        });
      props.dragCard(result);
    }
  };

  return (
    <>
      {/* <TabAction>
       <Filter/>
      </TabAction> */}
      <div className="h-full px-3 py-2  w-full" style={{maxHeight:"calc(100vh - 160px)"}}>
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-lists" direction="horizontal" type="list">
        {(provided) => (
          <div
            className="board-panal pb-2" 
            {...provided.droppableProps}
            ref={provided.innerRef}
            data-testid="onDragEnd"
          >
            <ListC />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext></div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  lists: state.opening.boardList,
  currentOpening: state.opening.currentOpening,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  dragCard: (result: any) => dispatch(draggableCard(result)),
  dragList: (result: any) => dispatch(draggableList(result)),
  saveCurrentBoard: (board: Opening) => dispatch(initCurrentBoard(board)),
});
export default connect(mapStateToProps, mapPropsToState)(BoardPanel);
