import React, { useState } from "react";
import { Menu, Layout, ConfigProvider } from "antd";
import TabAction from "../../header/TabAction";
import PublicURLMenu from "../../../../PublicURLMenu";
import { Space } from "antd/lib";

const { Sider, Content } = Layout;

const SettingsPanel = () => {
  const renderContent = () => {
        return <PublicURLMenu />;
  };

  return (
    <div className="h-full flex flex-col">
      <div className="px-3 py-2">
        <div className="bg-white rounded">
          <Layout
            style={{
              borderRadius: 4,
              height: "calc(-187px + 100vh)",
            }}
          >
            <Sider width={200} className="site-layout-background border-r h-full ">
              <ConfigProvider
                theme={{
                  components: {
                    Menu: {
                      colorText: "black",
                      colorBgContainer: undefined,
                      itemBg: "white",
                    },
                  },
                }}
              >
                <Menu
                activeKey="public-link"
                  mode="inline"
                  className="rounded-bl rounded-tl h-full overflow-y-scroll p-2"
                  defaultSelectedKeys={["public-link"]}
                  style={{ height: "100%", borderRight: 0 }}
                >
                  <Menu.Item key={"public-link"}>
                    Public job URL
                    </Menu.Item>
                 
                </Menu>
              </ConfigProvider>
            </Sider>
            <Content className="h-full bg-white rounded">
              {renderContent()}
            </Content>
          </Layout>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
