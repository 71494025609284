import { useEffect } from "react";
import { connect } from "react-redux";
import { PermissionAccess } from "./type/type";

type ReadType = {
  children: any;
  access?: PermissionAccess;
  type?: boolean; // If true, the component will not display any content. This is used to hide a specific component from certain roles such as members and commenters.
};
const Read = (props: ReadType) => {
  useEffect(() => {
    if (props.access && props.access.comment_only) {
      var el: any = document.querySelectorAll(".isEditable");
      var i;
      for (i = 0; i < el.length; i++) {
        el[i].setAttribute("disabled", true);
      }
    }
    return () => {};
  }, [props.children]);
  return props.access && props.access.comment_only
    ? null
    : props.access?.admin
    ? props.children
    : props.type
    ? null
    : props.children;
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(Read);
