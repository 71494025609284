import React, { useEffect, useState } from 'react'
import './App.css'
import Board from './pages/Board'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Card from './components/card/Card'
import Tacitbase from './Tacitbase'
import Login from './Login'
import Openings from './Openings'
import PageNotFound from './PageNotFound'
import LandingPage from './LandingPage'
import PageLoader from './LoadingScreen'

function SecureShareApp() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000) // Adjust the delay as needed

    return () => clearTimeout(timer)
  }, [])

  if (loading) {
    return (
      <PageLoader
      />
    ) 
  }

  return (
    <Router>
      <Tacitbase>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/identity/:base64String' component={Login} />
          <Route exact path='/o/:org_id/homepage' component={Openings} />
          <Route key=':board_id' path='/o/:org_id/b/:board_id/:tabname' component={Board} />
          <Route path='*' component={PageNotFound} />
        </Switch>
        <Route exact path='/o/:org_id/b/:board_id/tracker/:card_id/' component={Card} key=':card_id' />
      </Tacitbase>
    </Router>
  )
}

export default SecureShareApp
