import {  Typography, Tooltip, Input } from "antd"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Jd } from "../../type/type"
import { LabeledIconButton } from "../../ant/Button"
import { CopyOutlined, ExportOutlined, LinkOutlined } from "@ant-design/icons"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"

import { Dispatch } from "redux"
import { updateCurrentJd } from "../../redux/actions/opening/action"

const { Text } = Typography

const JDIntegrations = (props: { currentTrackerJD: Jd, updateCurrentJd: (JD: Jd) => void }) => {
        const [tooltipVisible, setTooltipVisible] = useState(false)
  

    function openRoute() {
        const route = props.currentTrackerJD.jd_public_url
        window.open(route, '_blank')
    }
   
    const handleCopy = () => {
        const textToCopy = props.currentTrackerJD.jd_public_url ? props.currentTrackerJD.jd_public_url : "Not available"
        navigator.clipboard.writeText(textToCopy).then(() => {
            setTooltipVisible(true)
            setTimeout(() => setTooltipVisible(false), 1000)
        })
    }



    return (
        <>
         <div className="px-4 pt-4" style={{
          height: "calc(-195px + 100vh)"
        }} >
      
            <div className="overflow-y-scroll h-full flex flex-col">
                <Text strong>{capitalizeFirstLetter("direct link")}</Text>
                <div className="space-x-2 justify-start items-center w-full flex  2xl:w-2/5 xl:w-1/2  xs:w-full  pr-2">
                    <Input readOnly addonBefore={<LinkOutlined />} addonAfter={<Tooltip title="Copied to clipboard!" visible={tooltipVisible}>
                        <CopyOutlined onClick={handleCopy} />
                    </Tooltip>} defaultValue={props.currentTrackerJD.jd_public_url ? props.currentTrackerJD.jd_public_url : "Not available"} />
                    <LabeledIconButton onClick={openRoute} label={""} icon={<ExportOutlined />} />
                    {/* <LabeledIconButton onClick={handleJDStatusUpdate} label={capitalizeFirstLetter(props.currentTrackerJD.status === "draft" || props.currentTrackerJD.status === UNPUBLISH ? PUBLISH : UNPUBLISH)} /> */}


                </div>
                <Text style={{ color: "#666666" }} className="my-2">{"The candidate who applies using this link will be considered in your sourcing."}
                </Text>
               

            </div>
            </div>
            {/* </div> */}
        </>
    )
}

const mapStateToProps = (state: any) => ({
    companyBoard: state.opening.companyBoards,
    applicationId: state.opening.applicationId,
    currentTrackerJD: state.opening.currentTrackerJd,
})

const mapPropsToState = (dispatch: Dispatch) => ({
    updateCurrentJd: (JD: Jd) =>
        dispatch(updateCurrentJd(JD)),
})
export default connect(mapStateToProps, mapPropsToState)(JDIntegrations)
