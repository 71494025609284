import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { currentApplicant, loginUser, orgMember } from "../../../../type/type";
import CommentIcon from "../../../../assets/Comments";
import CommentInput from "./CommentInput";
import TacitbaseAvtar from "../../../../ant/Avtar";
import { generateTooltipForMember } from "../../../../utils/capitalizeFirstLetter";

type CardEmailAndCommentType = {
  loginUser: loginUser;
  currentApplicant: currentApplicant;
  members: orgMember
};
const CardEmailAndComment = (props: CardEmailAndCommentType) => {
  const [isCommentPanal, setCommentPanal] = useState<boolean>(true)

  const showComment = () => {
    setCommentPanal(true);
  };
  const closeAll = () => {
    setCommentPanal(false);
  };
  
  return (
    <>
      <div className="flex items-center mb-2 ml-3 space-x-2">
        <div className="flex items-center  flex-shrink-0 justify-cente">
          {props.members[props.loginUser.user.id] ? props.members[props.loginUser.user.id].image_url ?
            <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.members[props.loginUser.user.id])}
              src={props.loginUser.user.imageUrl}
              size="default"
            /> : <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.members[props.loginUser.user.id])}
              content={props.members[props.loginUser.user.id].first_name[0] + props.members[props.loginUser.user.id].last_name[0]}
              size="default"
            /> : null}
        </div>
        <div className="flex space-x-2 items-center justify-center">
          {" "}
          <button
            onClick={showComment}
            className={`${
              isCommentPanal ? "border-l-4 border-tacitbase-secondary3" : ""
            } bg-gray-200 rounded text-xs px-2 py-1.5 flex justify-center space-x-1 items-center`}
          >
            <CommentIcon />
            <span>Add comment</span>
          </button>
          
        </div>
      </div>
      
      {isCommentPanal && (
        <>
          <CommentInput close={closeAll} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  currentApplicant: state.opening.currentApplicant,
  members: state.opening.members,
});

export default connect(mapStateToProps)(CardEmailAndComment);
