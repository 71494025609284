import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { Dispatch } from "redux";
import { editName } from "../../../redux/actions/opening/action";
import { useRef } from "react";
import CloseIcon from "../../../assets/Close";
import { Link, useHistory } from "react-router-dom";
import { currentApplicant, Opening, Scope } from "../../../type/type.d";
import {   TrackerRoute } from "../../../constant";
import { Overlay } from "../../hook/Overlay";
export type CardPreviewInputProps = {
  currentApplicant: currentApplicant;
  clientId: any;
  boardLists: any;
  updateApplicantName(nameObj: any): void;
  currentOpening: Opening;
  applicationId: string;
};
const CardInput = (props: CardPreviewInputProps) => {
  const history = useHistory();
  const [cardName, setCardName] = useState(props.currentApplicant.name);
  const txtRef = useRef<HTMLTextAreaElement>(null);

  const handleCancel = () => {
    history.push(`/o/${props.applicationId}/b/${props.currentOpening?.id}/${TrackerRoute}`) 
   };
  useEffect(() => {
    document.getElementById("card-close")?.focus();
    if (txtRef.current && txtRef.current.value) {
      txtRef.current.focus();
    }
  }, []);
  useEffect(() => {
    setCardName(props.currentApplicant.name);
  }, [props.currentApplicant.name]);
  
  return (
    <Overlay onClose={handleCancel} scope={Scope.Dialog}>
      <div className="mt-3 px-1 mr-2 ml-12  min-h-8 relative flex ">
        <i className="fa fa-credit-card fa-md absolute mt-1 -ml-8"></i>
        <TextareaAutosize
          ref={txtRef}
          data-testid="cardInput"
          className="isEditable resize-none inputCSSOnlyForFocus overflow-hidden mr-1 focus:bg-white mb-1 bg-gray-100 bg-opacity-50 font-semibold text-xl px-1 rounded w-full "
          value={cardName}
          onChange={(e) => {
            setCardName(e.target.value);
          }}
          // onKeyDown={(e) => editCardName(e)}
          maxLength={512}
          onFocus={(f: any) => f.target.select()}
          // onBlur={(e) => editCardName(e)}
          tabIndex={0}
          id="card-input"
        />
        <div className="flex justify-center items-start h-8 pt-1  w-8 hover:bg-gray-200 rounded-default">
          {" "}
          <Link
            to={`/o/${props.applicationId}/b/${props.currentOpening?.id}/${TrackerRoute}`}
            tabIndex={-1}
          >
            <button tabIndex={0} className="p-1">
              <CloseIcon />
            </button>
          </Link>
        </div>
      </div>{" "}
      <div className=" px-1  mr-2 ml-14 mb-2 -mt-2 truncate">
        <span className="text-gray-500 text-sm font-medium tracking-wider ">
          in list
        </span>{" "}
        <span className="font-extrabold  text-sm text-gray-500 text-sm  tracking-wider">
          {" "}
          {props.boardLists[props.currentApplicant.list_id]
            ? props.boardLists[props.currentApplicant.list_id].name
            : ""}
        </span>
      </div>
    </Overlay>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  boardLists: state.opening.boardList,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateApplicantName: (nameObj: any) => dispatch(editName(nameObj)),
});
export default connect(mapStateToProps, mapPropsToState)(CardInput);
