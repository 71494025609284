import React from "react";
import { connect } from "react-redux";
import CloseIcon from "../../../assets/Close";
import { orgMember, Registration } from "../../../type/type"
import SlackIcon from "../../../assets/SlackIcon"
type ShowMemberInInputProps = {
  memberId: string;
  members: orgMember;
  index: number;
  removeMemberFromSelectedList: (idx: number) => void;
};
const ShowMemberInInput = (props: ShowMemberInInputProps) => {  
  return (
    <>
      <div className="text-xs flex font-medium flex-none items-center mt-1 mr-1 bg-gray-200 px-1 rounded-default py-0.5">
        <div  
          className="space-x-1 flex"
          data-testid={props.members[props.memberId].first_name}
        >
          <div>{props.members[props.memberId].first_name}</div>{" "}
          <div>{props.members[props.memberId].last_name}</div>
          {props.members[props.memberId].registrations.some((registration: Registration) => registration.data.source === "slack") && (
            <SlackIcon size="14" />
          )}
        </div>
        <button
          tabIndex={0}
          className="p-1"
          data-testid="removeMember"
          onClick={() => props.removeMemberFromSelectedList(props.index)}
        >
          <CloseIcon size="12px" />
        </button>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
});
export default connect(mapStateToProps)(ShowMemberInInput);
