import React from "react"
import { connect } from "react-redux";
import { comment, orgMember } from "../../../../type/type"
import EditComment from "../../editComment/EditComment";
import TacitbaseAvtar from "../../../../ant/Avtar"
import { generateTooltipForMember } from "../../../../utils/capitalizeFirstLetter"
import { renderCreatedAt } from "../../../../utils/renderCreatedAt";

type ShowCommentProps = {
  comment: comment;
  members: orgMember;
};
const ShowComments = (props: ShowCommentProps) => {
  return (
    <div className="flex items-start mb-4 space-x-2">
      <div className="flex items-center mt-1 flex-shrink-0  justify-cente">
        {props.members[props.comment.member_id] ? props.members[props.comment.member_id].image_url ?
          <TacitbaseAvtar
            toolTip={generateTooltipForMember(props.members[props.comment.member_id])}
            src={props.members[props.comment.member_id].image_url}
            size="default"
          /> : <TacitbaseAvtar
            toolTip={generateTooltipForMember(props.members[props.comment.member_id])}
            content={props.members[props.comment.member_id].first_name[0] + props.members[props.comment.member_id].last_name[0]}
            size="default"
          />
          : null
        }
      </div>
      <div className="flex flex-col w-full">
        <div className="inline text-sm  break-words ">
          <span className="font-semibold cursor-default tracking-wide mr-1 ">
            {props.members[props.comment.member_id] && props.members[props.comment.member_id].first_name}{" "}
          </span>
          <span className="text-xs font-medium mr-1  text-gray-500">
            {renderCreatedAt(props.comment?.created_at)}
          </span>
        </div>
        <EditComment comments={props.comment} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});

export default connect(mapStateToProps)(ShowComments);
