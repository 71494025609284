import React from "react";
import {  Image, Layout, Space, Spin } from "antd";
import secureShareLogo from './images/secure-share_logo.png'
import { Footer } from "antd/es/layout/layout";
import { capitalizeFirstLetter } from "./utils/capitalizeFirstLetter";
import Link from "antd/es/typography/Link";

const { Content } = Layout;



const PageLoader: React.FC = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="flex flex-col space-y-6" style={{ padding: "50px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div> <div className='flex flex-col space-y-6'>
            {' '}
            <Spin tip='' size='large'>
              <div className='content' />
            </Spin>
          </div></div>
       
        <div style={{ position: "absolute", bottom: "20px" }}>
        <div className='flex justify-center'>
            <Space>
              <Image width={150} src={secureShareLogo} preview={false} />
            </Space>
          </div>
          <Footer
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    color: 'black',
                    height: 35,
                    borderTop:
                     '1px solid rgba(255,255,255,0.25)',
                  }}
                  className=' bg-transparent'
                >
                  <div className='h-full' style={{ background: '' }}>
                    <div className='flex justify-center w-full items-center h-full'>
                      <span className='text-xs'>
                        {capitalizeFirstLetter('Powered by')}{' '}
                        <Link
                          target='_blank'
                          href='https://www.tacitbase.com/'
                          className='font-semibold text-sm'
                          style={{ color: '#F26046' }}
                        >
                          Tacitbase
                        </Link>
                      </span>
                    </div>
                  </div>
                </Footer>

        </div>
      </Content>
    </Layout>
  );
};

export default PageLoader;
