import React, { useState } from "react"
import { connect } from "react-redux";
import AddIcon from "../../../assets/Add";
import Read from "../../../Read";
import { Opening } from "../../../type/type.d"
import { Popover } from "antd"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import { ADD_MEMBER_TITLE } from "../../../constant"
import { Typography } from "antd"
import { LabeledIconButton } from "../../../ant/Button"
import { CloseOutlined } from "@ant-design/icons"
import AddBoardMemberPopup from "./AddBoardMemberPopup";
const { Text } = Typography
type AddBoardMemberProps = {
  currentOpening: Opening;
};

const AddBoardMember = (props: AddBoardMemberProps) => {
  const [open, setOpen] = useState(false);

  return (

    <Read access={props.currentOpening.access} type={true}>
      <Popover destroyTooltipOnHide title={<div className="flex w-full bg-white items-center">
        <Text className="w-full flex justify-center">
          <Text>{capitalizeFirstLetter(ADD_MEMBER_TITLE)}</Text>
        </Text>
        <LabeledIconButton
          type="text"
          label=""
          onClick={() => setOpen(false)}
          icon={<CloseOutlined />}
        />
      </div>} open={open} onOpenChange={(open) => setOpen(open)} trigger={["click"]}
        content={
          <AddBoardMemberPopup click={() => setOpen(!open)} />
        }>
        
        <div className="" >
        <div
          className="w-8 nav-btn items-center"
            onClick={() => setOpen(true)}
          aria-hidden
          data-testid="openMemberPopup" style={{height:30}}
        >
          {" "}
          <AddIcon fill="white" />
        </div>
      </div>
      </Popover>
    </Read >
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(AddBoardMember);
