import React, { useState } from "react";
import { connect } from "react-redux";
import MemberIcon from "../../../assets/Members12*12";
import TickIcon from "../../../assets/Tick";
import { customRoles, PUBLIC } from "../../../constant";
import { Opening } from "../../../type/type";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import PopupTitle from "../../common/popupTitle/PopupTitle";
type ShowBoardVisibilityPopupProps = {
  refer: any;
  close: () => void;
  currentOpening: Opening;
  visibilityActions: customRoles[];
};
const ShowBoardVisibilityPopup = (props: ShowBoardVisibilityPopupProps) => {
  const [isShowConfirmPrompt, showPrompt] = useState<boolean>(false);
  return (
    <>
      <div className="border border-tacitbase-tertiary1 border-opacity-25 right-0 z-10 w-72 mt-1 max-h-80 origin-top-right rounded-default bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="m-2">
          <PopupTitle PopupTitle="change visibility" close={props.close} />
        </div>
        {props.visibilityActions.map((visibility: customRoles, idx) => (
          <div
            onClick={() =>
              !props.currentOpening.member_only && visibility.name == PUBLIC
                ? props.close()
                : props.currentOpening.member_only && visibility.name !== PUBLIC
                ? props.close()
                : showPrompt(!isShowConfirmPrompt)
            }
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            key={idx}
            className="flex hover:bg-gray-200 hover:bg-opacity-40 m-1"
          >
            <div
              className={`flex py-1.5 justify-between items-center hover:bg-gray-300 rounded-default hover:bg-opacity-40 w-full`}
              onClick={() => {}}
              onKeyDown={() => {}}
              tabIndex={0}
              role="button"
              data-testid="add-member"
            >
              <div className="flex space-x-1.5 w-full px-1">
                <div className="flex justify-between w-full items-center">
                  <div className="flex flex-col justify-center w-full">
                    <div className="space-x-1 flex items-center ">
                      <div>
                        {visibility.name == "public" ? (
                          <i
                            className="fa fa-globe text-black"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <MemberIcon />
                        )}
                      </div>
                      <div className="leading-snug space-x-1 flex font-medium text-sm text-gray-800 tracking-normal line-clamp-1">
                        {" "}
                        {capitalizeFirstLetter(visibility.name)}
                      </div>
                    </div>
                    <div className="font-medium  text-xs text-gray-500 tracking-normal">
                      {visibility.description}
                    </div>
                  </div>
                  {!props.currentOpening.member_only &&
                  visibility.name == PUBLIC ? (
                    <TickIcon />
                  ) : props.currentOpening.member_only &&
                    visibility.name !== PUBLIC ? (
                    <TickIcon />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isShowConfirmPrompt ? (
        <></>
        // <ShowConfirmationPopup close={() => showPrompt(!isShowConfirmPrompt)} />
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(ShowBoardVisibilityPopup);
