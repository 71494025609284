import React from 'react'
import { Button, Image, Layout } from 'antd'
import { InfoCircleOutlined, LinkOutlined, EyeOutlined } from '@ant-design/icons'
const { Header, Content } = Layout
import secureShareLogo from "./images/secure-share_logo.png";
import Footer from './components/Footer';

const LandingPage: React.FC = () => {
  return (
    <Layout className='min-h-screen'>
      <Header className='bg-white shadow-md flex justify-between items-center px-4'>
        <div className='text-xl font-bold flex items-end'>
          <Image width={150}  src={secureShareLogo} preview={false}/>
         </div>
      </Header>

      <Content className='flex-grow'>
        <section className='bg-gradient-to-r from-purple-500 to-blue-500 text-white text-center py-20'>
          <h1 className='text-4xl font-bold mb-4'>Securely share your tracker with guests</h1>
          <p className='text-lg mb-6'>Easily share and track your important information with guest users.</p>
        </section>

        <section className='py-20'>
          <div className='container mx-auto text-center'>
            <h2 className='text-3xl font-bold mb-8'>How it works</h2>
            <div className='flex justify-around items-start'>
              <div className='max-w-sm'>
                <InfoCircleOutlined className=' text-blue-500' style={{ fontSize: 20 }} />
                <h3 className='text-xl font-semibold mb-2'>Get the link via email</h3>
                <p>Receive an email with a secure link to access the tracker.</p>
              </div>
              <div className='max-w-sm'>
                <LinkOutlined className=' text-blue-500' style={{ fontSize: 20 }} />
                <h3 className='text-xl font-semibold mb-2'>Click on the link</h3>
                <p>Click the link to securely access the tracker on your browser.</p>
              </div>
              <div className='max-w-sm'>
                <EyeOutlined className=' text-blue-500' style={{ fontSize: 20 }} />
                <h3 className='text-xl font-semibold mb-2'>Track the Tracker</h3>
                <p>Follow and track the information as it gets updated in real-time.</p>
              </div>
            </div>
          </div>
        </section>
      </Content>

      <Footer/>
    </Layout>
  )
}

export default LandingPage
