import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ReactTextareaAutosize from "react-textarea-autosize";

//Api
import { Dispatch } from "redux";
// import UserIcon from "../../../../assets/User";
import { COMMENT_TAG, SOCKET_CLIENT_ID } from "../../../../constant";
import { createComment } from "../../../../lib/api";
import { saveComment } from "../../../../redux/actions/opening/action";
import { currentApplicant, loginUser } from "../../../../type/type";
import CommentInputActionButton from "../../CommentInputActionButton";
import OutsideClick from "../../../OutsideClick";
export type CommentInputProps = {
  currentApplicant: currentApplicant;
  saveComment(comment: any, listID: string): void;
  loginUser: loginUser;
  clientId: string;
  close: () => void
};

const CommentInput = (props: CommentInputProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const [Size, ISExceeds] = useState(false);
  const [tooLongBy, longBy] = useState(1);
  const [comment, setComment] = useState("");
  const [commentInput, showCommentInput] = useState({
    Input: false,
    style: "border-tacitbase-secondary3",
  });

  // const click = (value: boolean) => {
  //   if (value) setComment("");
  // };
  const validateCommentLength = (e: any) => {
    e.target.value.length > 5000
      ? (ISExceeds(true), longBy(e.target.value.length - 5000))
      : (ISExceeds(false), longBy(1));
    setComment(e.target.value);
  };

  const txtref = useRef<HTMLDivElement|null>(null);
  OutsideClick(txtref, () => {
    if (!comment.trim()) {
      showCommentInput({
        Input: false,
        style: "",
      });
    }
  });
  const saveComment = (e: any) => {
    if ((e.keyCode == 13 && e.metaKey) || e.button == 0) {
      const postComment = {
        body: comment,
        member_id: props.loginUser.user.id,
        applicant_id: props.currentApplicant.id,
        opening_id: props.currentApplicant.opening_id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        list_id: props.currentApplicant.list_id,
      };
      if (comment.trim().length > 0) {
        e.preventDefault();
        createComment(postComment)
          .then((comment: any) => {
            props.saveComment(comment, COMMENT_TAG);
            ref.current ? ref.current.blur() : null;
            showCommentInput({
              Input: false,
              style: "",
            });
            setComment("");
            props.close()
          })
        
      }
    }
  };
  useEffect(() => {
    ref.current && ref.current.focus()
  }, [])
  return (
    <React.Fragment>
      <div className="mb-2 ml-12 ">

        <div
          ref={txtref}
          className={`${
            commentInput.style
              ? commentInput.style
              : "border-tacitbase-tertiary1 border-opacity-20 "
            } border  h-auto rounded    bg-white`}
        >
          <ReactTextareaAutosize
            className={` ${
              commentInput.style ? "cursor-text" : "cursor-text"
              } h-8 w-full py-2 px-2  rounded focus:outline-none resize-none text-sm block overflow-hidden `}
            value={comment}
            style={{ wordBreak: "break-word" }}

            ref={ref}
            placeholder="Write a comment..."
            onChange={validateCommentLength}
            onKeyDown={(e: any) => saveComment(e)}

            // onClick={
            //   commentInput.Input
            //     ? (e: any) => {
            //         e.preventDefault;
            //       }
            //     : () => {
            //         showCommentInput({
            //           Input: true,
            //           style: "border-tacitbase-secondary3",
            //         });
            //       }
            // }
            tabIndex={0}
          ></ReactTextareaAutosize>
          {/* {commentInput.Input ? ( */}
            <CommentInputActionButton
            clickedClose={props.close}
              click={saveComment}
              commentValue={comment}
              comments={comment}
              isExceed={Size}
              CommentInput={(e) =>
                showCommentInput({
                  Input: e.Input,
                  style: e.style,
                })
              }
            />
          {/* ) : null} */}
        </div>
        {Size && (
          <div className="text-red-500 py-2">
            Your text is {tooLongBy} characters too long to save.
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  saveComment: (comment: any, listID: string) =>
    dispatch(saveComment(comment, listID)),
});
export default connect(mapStateToProps, mapPropsToState)(CommentInput);
